import { FunctionComponent, PropsWithChildren, ReactNode } from 'react'
import { cn } from 'ui/lib/utils'
import { Switch } from '@ui/components/ui/switch'
import { Label } from '@ui/components/ui/label'

export interface Props {
  id: string
  label?: ReactNode
  description?: string
  value: boolean
  disabled?: boolean
  loading?: boolean
  onChange: (value: boolean) => void
  descriptionClassName?: string
}

const MisterToggleWithDescription: FunctionComponent<PropsWithChildren<Props>> = ({
  id,
  label,
  description,
  value,
  disabled = false,
  loading = false,
  onChange,
  descriptionClassName,
}) => {
  return (
    <div className='flex items-center justify-between space-x-4 md:space-x-6 lg:space-x-8 2xl:max-w-3xl 2xl:space-x-10'>
      <span className='flex flex-grow flex-col space-y-2'>
        <Label className='!font-bold' htmlFor={id}>
          {label}
        </Label>
        <div className={cn(descriptionClassName)}>{description}</div>
      </span>
      <Switch
        id={id}
        checked={value}
        onCheckedChange={(checked) => !disabled && onChange(checked)}
        className={cn(
          value ? 'bg-brand-positive' : 'bg-neutral-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out',
          disabled || loading ? 'cursor-not-allowed' : 'cursor-pointer',
          (disabled || loading) && 'opacity-50',
          !disabled && 'focus:outline-none focus:ring-2 focus:ring-brand-medium-grey focus:ring-offset-2',
        )}
      />
    </div>
  )
}
export default MisterToggleWithDescription
